var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Vehicles__View" },
    [
      _c("EntityListLayout", {
        ref: "entity",
        attrs: { tabs: _vm.tabs },
        on: { onNewEntitySave: _vm.onNewEntitySave },
        scopedSlots: _vm._u(
          [
            {
              key: "title",
              fn: function() {
                return [_vm._v("רכבים")]
              },
              proxy: true
            },
            _vm.isAdmin
              ? {
                  key: "button",
                  fn: function() {
                    return [
                      _c("b-button", { on: { click: _vm.showNewEntity } }, [
                        _vm._v("הוספת רכב חדש")
                      ])
                    ]
                  },
                  proxy: true
                }
              : null,
            {
              key: "counter",
              fn: function(slotProps) {
                return [
                  _vm._v(' סה"כ '),
                  _c("b", [
                    _vm._v(_vm._s(_vm.getTotalRecords(slotProps)) + " ")
                  ])
                ]
              }
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }